.Heading_XL_highlights {
	font-family: 'Source Sans Pro';
	font-weight: 600;
	font-size: 40px;
	line-height: 48px;
	color: #000000;
}

.Heading_XL {
	font-family: 'Source Sans Pro';
	font-weight: 300;
	font-size: 40px;
	line-height: 48px;
	color: #000000;
}

.Heading_L {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	line-height: 40px;
	color: #000000;
}

.Heading_M {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	color: #000000;
}

.Heading_S {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	color: #000000;
}

.Body_L {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	color: #000000;
}

.Body_M_highlights {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}

.Body_M {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}

.Body_S_highlights {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
}

.Body_S {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
}

.Body_XS_highlights {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	color: #000000;
}

.Body_XS {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #000000;
}